aside {
    z-index: 125;
    height: 100%;
    width: 80%;
    min-width: 80%;
    position: absolute;
    top: 0;
    left: -80%;
    background-color: $dark-bg;
    color: $white;
    flex-direction: column;
    transition: all 0.2s ease-in-out;

    @include tablet() {
        width: $sidebar-width;
        min-width: $sidebar-width;
        left: -$sidebar-width;
    }

    @include desktop() {
        display: flex;
        position: inherit;
        float: left;
        left: 0;
    }

    > header {
        background-color: $sidebar-header-bg;
        height: $header-height;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 1.5em;

        > label {
            font-size: 1.8em;
            font-weight: 500;
            width: auto;
        }

        > div.button-container {
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-end;

            > button {
                background: transparent;
                border: none;
                color: lighten($dark-bg, 45%);
                padding: 5px;
                font-size: 1.8em;
                cursor: pointer;
                transition: all 0.2s;
                margin-right: 0;

                &:focus {
                    outline: none;
                }

                &.active {
                    color: $white;
                }
            }
        }
    }

    > section {
        flex: 1;
        height: calc(100% - #{$header-height});
        overflow: auto;
        padding: 1em;

        > div.keyword-search {
            position: relative;
            margin: 1em 0 2em;

            > label {
                position: absolute;
                top: 4px;
                left: 10px;
                font-size: 1.5em;
                color: #505255;
            }

            > button.clear-filter {
                position: absolute;
                top: 9px;
                right: 10px;
                padding: 0 5px;
                margin: 0;
                border: none;
                background: transparent;
                color: $white;
            }

            > input.search {
                background-color: $sidebar-header-bg !important;
                border-radius: 50px;
                border-color: transparent;
                padding: 0.5em 2.5em;
                width: 100%;
                font-size: 1em;
                color: lighten($dark-bg, 75%);
                box-sizing: border-box;

                &:focus {
                    outline: none;
                }

                &::-ms-clear {
                    display: none;
                }
            }
        }

        > section.filter-section {
            margin-bottom: 2em;
            display: flex;
            flex-flow: row wrap;
            max-height: 370px;
            overflow: hidden;

            &.active {
                max-height: inherit;
            }

            > .filter-section-header {
                width: 100%;
                margin-bottom: 1em;

                > h4 {
                    color: lighten($dark-bg, 35%);
                    font-size: 1.1em;
                    font-weight: 500;
                    display: inline-block;
                    margin: 0;
                }

                > button.clear-filter {
                    padding: 0;
                    margin: 0;
                    border: none;
                    background: transparent;
                    float: right;
                    color: $white;
                }
            }

            > span.show-more {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 1em;
                cursor: pointer;
                color: lighten($dark-bg, 50%);
            }

            > .filter-item {
                width: 100%;
                padding-bottom: 5px;
                padding-top: 8px;
                border-bottom: 1px solid lighten($dark-bg, 5%);
                color: lighten($dark-bg, 65%);

                display: flex;
                flex-direction: row;
                align-items: center;

                > label {
                    flex: 1;
                    font-size: 1.1em;
                    cursor: pointer;
                    padding-left: 1em;
                    padding-right: 1em;
                }

                > span {
                    font-size: 1.1em;
                }

                > input[type="checkbox"] {
                    cursor: pointer;
                    background: lighten($dark-bg, 15%);
                    border: none;
                    height: 15px;
                    width: 15px;
                    border-radius: 3px;
                    -webkit-appearance: initial;
                    display: inline-block;
                    transition: all 130ms ease-in-out;

                    &:focus {
                        outline: none;
                    }

                    &:checked {
                        background: $primary;
                        & + label {
                            color: $primary;
                        }
                    }
                }
            }
        }

        > section.credits {
            margin-top: 4em;
            color: lighten($dark-bg, 30%);

            span.powered-by {
                font-size: 1em;
            }

            .bullhorn {
              display: block;
              position: relative;
              bottom: 20px;
              margin: 0;
            }
        }

        > button[name="back-arrow"] {
            width: 46px;
            height: 100%;
            background: transparent;
            color: $white;
            border: none;
            display: none;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-radius: 0;
            background-color: $dark-bg;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            -webkit-box-shadow: inset 1px 1px 6px $base-color;
            background-color: $base-color;
        }
    }

    &.show {
        display: block !important;
    }

    // Parent selector
    .overview-open & {
        width: $sidebar-width-small;
        min-width: $sidebar-width-small;

        > header {
            display: none;
        }

        > section {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div.keyword-search, > section.filter-section, > section.credits {
                display: none;
            }

            > button[name="back-arrow"] {
                display: block;
            }

            > button[name="clear-all"] {
                display: none;
            }
        }

        &:hover {
            cursor: pointer;
            background: lighten($dark-bg, 3%);
        }
    }
}
